import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
// import { css } from "styled-components/macro";
import { SectionDescription } from "components/misc/Typography.js";
import TeamIllustrationSrc from "../../images/post-il-canino-cocker.jpg";
import imagen2 from "../../images/imagenhome2.png";
import imagen3 from "../../images/imagen3-2.jpg";
import imagen4 from "../../images/imagen4-2.jpg";
// import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
// import CustomizeIconImage from "images/customize-icon.svg";
// import FastIconImage from "images/fast-icon.svg";
// import ReliableIconImage from "images/reliable-icon.svg";
// import SimpleIconImage from "images/simple-icon.svg";

const Container = tw.div`relative mt-16 px-6`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-[1290px] mx-auto pb-20 md:pb-24`}
`;
// const Subheading = tw(SubheadingBase)`mb-4`;
const SectionHeading = tw.h2`text-2xl sm:text-5xl font-black tracking-wide text-center`
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`


const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-center h-full mx-0 px-0 py-3 md:py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;



const Column = styled.div`
  ${tw` lg:w-1/3 `}
`;
const ImageColumn = tw(Column)`md:w-4/12 flex-shrink-0 flex justify-center gap-6 relative mb-12`;
const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
  props.customcss && tw`object-contain`,
]);

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({   buttonRounded = true,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  textOnLeft = true, cards = null, heading = "Amazing Features", subheading = "Features", description = "Il Canino se compromete a ofrecer servicios personalizados de peluquería y veterinaria, promoviendo la salud y el bienestar de nuestros amigos de cuatro patas." }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: ShieldIconImage,
      title: "MISIÓN",
      description: "Ofrecer servicios personalizados de peluquería y veterinaria para la salud y bienestar de los perros.",
    },
    { imageSrc: SupportIconImage, title: "VALORES" , description: "Calidad, Transparencia, Responsabilidad, Confianza y Innovación"},
    { imageSrc: SupportIconImage, title: "VISIÓN" , description: "Posicionarnos como líderes del mercado con excelencia en el cuidado canino, transparencia y servicios personalizados."},
    
    
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container id="inicio">
      <ThreeColumnContainer>
        {/* {subheading && <Subheading>{subheading}</Subheading>} */}
        <ImageColumn>
            <Image css={imageCss} src={TeamIllustrationSrc} imageShadow={imageShadow} imageRounded={imageRounded} />
            <Image css={imageCss} src={imagen3} imageShadow={imageShadow} customcss='object-contain' imageRounded={imageRounded} />
            <Image css={imageCss} src={imagen4} imageShadow={imageShadow} customcss='object-contain' imageRounded={imageRounded} />
            {/* <img className="w-[200px] h-auto" src={image1} /> */}
          
        </ImageColumn>
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
