import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { BsHouseDoor } from "react-icons/bs";
import { FiPhone } from "react-icons/fi";
import LogoImage from "images/logo-png.png";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
import { FaWhatsapp, FaInstagram, FaSquareFacebook } from "react-icons/fa6";
import { MdOutgoingMail } from "react-icons/md";
const Container = tw.div`relative bg-[#0d6980] text-gray-100 -mb-8 -mx-8 px-8 py-20 lg:py-24 `;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10 px-6`;
const FiveColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/3 md:w-auto mt-12`;

const ColumnHeading = tw.a`uppercase font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-100 pb-1 transition duration-300`;

const Divider = tw.div`my-16 border-b-2 border-[#02e0ea] w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-start justify-between`;
const ThreeColRow2 = tw.div`flex flex-col md:flex-row items-center justify-center`;

const LogoContainer = tw.div`flex items-center w-full md:w-auto justify-center md:justify-start`;
const LogoImg = tw.img`w-32`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-100`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-400`;
const CopywrightNotice2 = tw.p`text-start md:text-start text-sm sm:text-base mt-8 md:mt-0 font-medium text-white flex items-center gap-3`;
const CopywrightNotice3 = tw.p`text-center md:text-start text-sm sm:text-base mt-8 md:mt-0 font-medium text-white flex flex-col items-start gap-3`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-400 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const SocialLink2 = styled.a`
  ${tw`cursor-pointer p-2  text-white  transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute top-0 left-0 w-80 h-80 transform -translate-x-20 -translate-y-32 text-[#02e0ea] opacity-50`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1
)`absolute bottom-0 right-0 w-80 h-80 transform  translate-x-32 translate-y-48 text-[#02e0ea] opacity-50`;

export default () => {
  return (
    <Container>
      <Content>
        <FiveColumns>
          <Column>
            <ColumnHeading> <Link href="https://ilcaninopetspa.com/">INICIO</Link></ColumnHeading>
            {/* <LinkList>
              <LinkListItem>
                <Link href="#">Blog</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">FAQs</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Support</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">About Us</Link>
              </LinkListItem>
            </LinkList> */}
          </Column>
          <Column>
            <ColumnHeading> <Link href="https://ilcaninopetspa.com/#nosotros">NOSOTROS</Link></ColumnHeading>
            {/* <LinkList>
              <LinkListItem>
                <Link href="#">Log In</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Personal</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Business</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Team</Link>
              </LinkListItem>
            </LinkList> */}
          </Column>
          <Column>
            <ColumnHeading> <Link href="https://ilcaninopetspa.com/#servicio">SERVICIOS</Link></ColumnHeading>

            {/* <LinkList>
              <LinkListItem>
                <Link href="#">Logos</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Events</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Stories</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Office</Link>
              </LinkListItem>
            </LinkList> */}
          </Column>
          <Column>
            <ColumnHeading> <Link href="https://ilcaninopetspa.com/#producto">PRODUCTOS</Link></ColumnHeading>

            {/* <LinkList>
              <LinkListItem>
                <Link href="#">Career</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Founders</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Culture</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Onboarding</Link>
              </LinkListItem>
            </LinkList> */}
          </Column>
          <Column>
            <ColumnHeading> <Link href="https://ilcaninopetspa.com/contact">CONTACTO</Link>         

            </ColumnHeading>
            {/* <LinkList>
              <LinkListItem>
                <Link href="#">GDPR</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Privacy Policy</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Terms of Service</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Disclaimer</Link>
              </LinkListItem>
            </LinkList> */}
          </Column>
          <Column>
            <ColumnHeading>
              <Link href="https://ilcaninopetspa.com/products">TIENDA</Link>   
            </ColumnHeading>
            {/* <LinkList>
              <LinkListItem>
                <Link href="#">GDPR</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Privacy Policy</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Terms of Service</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Disclaimer</Link>
              </LinkListItem>
            </LinkList> */}
          </Column>
        </FiveColumns>
        <Divider />
        <ThreeColRow>
          <LogoContainer>
            <LogoImg src={LogoImage} />
          </LogoContainer>
          <CopywrightNotice3>
            <CopywrightNotice2><BsHouseDoor />Calle José Amado Soler esq. Calle 2A, Plaza Alina Isabel, Santo Domingo</CopywrightNotice2>
             <CopywrightNotice2><FiPhone />+1 (809) 899 6315 </CopywrightNotice2>
             <CopywrightNotice2><FiPhone />(809) 255 5854</CopywrightNotice2>
            <CopywrightNotice2><MdOutgoingMail />ilcaninopetspa@gmail.com

            </CopywrightNotice2>

          </CopywrightNotice3>
          <SocialLinksContainer>
            <SocialLink href="https://www.instagram.com/ilcaninopetspa/">
              <FaInstagram />
            </SocialLink>
            <SocialLink href="https://www.facebook.com/ilcaninopetspa/">
              <FaSquareFacebook />
            </SocialLink>
            <SocialLink href="https://wa.me/+18098996315?">
              <FaWhatsapp />
            </SocialLink>
            <SocialLink href="mailto:ilcaninopetspa@gmail.com">
              <MdOutgoingMail />
            </SocialLink>
          </SocialLinksContainer>
        </ThreeColRow>

        <Divider />

        <ThreeColRow2>
         <CopywrightNotice>&copy; 2024 IL CANINO PET SPA. All Rights Reserved.</CopywrightNotice>
         
        </ThreeColRow2>


      </Content>

      <DecoratorBlobContainer>
        <DecoratorBlob1 />
        <DecoratorBlob2 />
      </DecoratorBlobContainer>
    </Container>
  );
};
