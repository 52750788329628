import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import { FaWhatsapp, FaInstagram, FaSquareFacebook } from "react-icons/fa6";
import { MdOutlinePhoneEnabled } from "react-icons/md";
import { MdOutgoingMail } from "react-icons/md";
const Address = tw.span`leading-relaxed text-center`;
const AddressLine = tw.span`block text-center`;
const Email = tw.span`text-sm mt-6 block text-gray-700 text-center`;
const Phone = tw.span`text-sm mt-0 block text-gray-700 text-center`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header 
       inicio='/'
      />
      <ContactUsForm />
      <ContactDetails
        cards={[
          

        
 

          {
            icon: <MdOutlinePhoneEnabled />,
            title: "Teléfono",
            description: (
              <>
                <Address>
                  <AddressLine>(809) 255 5854</AddressLine>
                </Address>
              </>
            )
          },

          {
            icon: <FaWhatsapp/>,
            title: "Whatsapp",
            description: (
              <>
                <Address>
                  <AddressLine>+1 (809) 899 6315</AddressLine>
                </Address>
               
              </>
            )
          },
          {
            icon: <FaInstagram />,
            title: "Instagram",
            description: (
              <>
                <Address>
                  <AddressLine>@ilcaninopetspa</AddressLine>
                </Address>
              </>
            )
          },
          {
            icon: <FaSquareFacebook />,
            title: "Facebook",
            description: (
              <>
                <Address>
                  <AddressLine>Il Canino</AddressLine>
                </Address>
              </>
            )
          },
          {
            icon: <MdOutgoingMail/>,
            title: "Email",
            description: (
              <>
                <Address>
                  <AddressLine>ilcaninopetspa@gmail.com</AddressLine>
                </Address>
              </>
            )
          }
         
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
