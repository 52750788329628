import React from "react";
import tw from "twin.macro";
// import { css } from "styled-components/macro"; //eslint-disable-line
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import Features2 from "components/features/ThreeColWithSideImage2.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
// import FeatureWithSteps from "components/features/TwoColWithSteps.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
// import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import image1 from '../images/nosotros-1.png'
import image3 from '../images/post-il-canino-cocker.jpg'
import { LiaCookieSolid } from "react-icons/lia";
import { MdOutlineHealthAndSafety } from "react-icons/md";
import { LuCandy } from "react-icons/lu";
import { GiHeartNecklace  } from "react-icons/gi";
import { GiHealthPotion } from "react-icons/gi";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-[#02e0ea]`;
  const HighlightedText = tw.span`text-[#1f628d]`;
  const HighlightedText2 = tw.span`text-[#02e0ea]`;

  const Coockie = () =>{
    return <LiaCookieSolid />
  }
  const Salud = () =>{
    return <MdOutlineHealthAndSafety />
  }
  const Dulce = () =>{
    return <LuCandy />
  }
  const Collar = () =>{
    return <GiHeartNecklace  />
  }
  const Potion = () =>{
    return <GiHealthPotion  />
  }
  return (
    <AnimationRevealPage>

   

          
      <Hero roundedHeaderButton={true} />
      <Features2
          imageBorder={true}
          imageDecoratorBlob={true}
          imageSrc={image3}
        // subheading={<Subheading>SERVICIOS</Subheading>}
        heading={
          <>
            Compromiso Canino: <HighlightedText>Cuidado Personalizado, Excelencia y Transparencia</HighlightedText>
          </>
        }
      />
      <MainFeature
        imageSrc={image1}
        subheading={<Subheading>NOSOTROS</Subheading>}
        // imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      />

      <Features

        subheading={<Subheading>SERVICIOS</Subheading>}
        heading={
          <>
            Descubre nuestros <HighlightedText>servicios.</HighlightedText>
          </>
        }
      />
      {/* <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      /> */}
      <MainFeature2
        subheading={<Subheading>PRODUCTOS</Subheading>}
        heading={
          <>
           En nuestra tienda, tenemos todo lo que necesitas para cuidar a tu <HighlightedText2>mejor amigo.</HighlightedText2>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: Coockie,
            title: "Alimentos",
            description: "Ofrecemos una amplia selección de alimentos premium para perros, formulados para satisfacer sus necesidades nutricionales específicas y mantenerlos sanos y felices.",
            iconContainerCss: tw`bg-white text-2xl text-[#02e0ea]`
          },
          {
            Icon: Dulce,
            title: "Snacks & Treats",
            description: "Nuestros deliciosos treats, ideales como premios durante el entrenamiento o simplemente para consentirlos en cualquier momento del día.",
            iconContainerCss: tw`bg-white text-[#02e0ea]`
          },
          {
            Icon: Salud,
            title: "Higiene y Cuidado",
            description: "Mantén a tu perrito limpio y saludable con nuestra gama de productos de higiene y cuidado, que incluyen champús suaves, cepillos y productos para el cuidado dental.",
            iconContainerCss: tw`bg-white text-[#02e0ea]`
          },
          {
            Icon: Collar,
            title: "Accesorios",
            description: "Desde cómodas camas y juguetes interactivos hasta correas elegantes y collares, ofrecemos una variedad de accesorios que harán que la vida de tu perro sea más cómoda, segura y divertida.",
            iconContainerCss: tw`bg-white text-[#02e0ea]`
          },
          {
            Icon: Potion,
            title: "Salud",
            description: "Cuida de la salud y el bienestar de tu perrito con nuestra selección de productos para el cuidado de la salud, que incluyen suplementos nutricionales, productos para el control de parásitos y artículos para el cuidado de las articulaciones y la piel.",
            iconContainerCss: tw`bg-white text-[#02e0ea]`
          }
        ]}
      />
      {/* <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Personal",
            price: "$17.99",
            duration: "Monthly",
            mainFeature: "For Individuals",
            features: ["30 Templates", "7 Landing Pages", "12 Internal Pages", "Basic Assistance"]
          },
          {
            name: "Business",
            price: "$37.99",
            duration: "Monthly",
            mainFeature: "For Small Businesses",
            features: ["60 Templates", "15 Landing Pages", "22 Internal Pages", "Priority Assistance"],
            featured: true
          },
          {
            name: "Enterprise",
            price: "$57.99",
            duration: "Monthly",
            mainFeature: "For Large Companies",
            features: ["90 Templates", "27 Landing Pages", "37 Internal Pages", "Personal Assistance"]
          }
        ]}
      /> */}
      {/* <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Amazing User Experience",
            quote:
              "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
            customerName: "Charlotte Hale",
            customerTitle: "Director, Delos Inc."
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Love the Developer Experience and Design Principles !",
            quote:
              "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Adam Cuppy",
            customerTitle: "Founder, EventsNYC"
          }
        ]}
      /> */}
      <FAQ
        // subheading={<Subheading>Tienes alguna pregunta?</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "¿Trabajan con citas?",
            answer:
              "No, trabajamos por orden de llegada. De esta manera, atendemos a todos nuestros clientes de manera justa y en el orden en que llegan, sin necesidad de programar una cita con anticipación."
          },
          {
            question: "¿Cuáles son los horarios de trabajo de Il Canino?",
            answer:
              "LUNES A VIERNES: 8:30AM - 6:00PM / SÁBADOS: 9:00AM - 3:00PM "
          },
          {
            question: "¿Hasta qué hora reciben a los clientes?",
            answer:
              "DE LUNES A VIERNES recibimos de 8:30AM a 4:30PM y LOS SÁBADOS de 9:00AM a 2:00PM."
          },
          {
            question: "¿Tienen disponibilidad de hotel para todas las fechas?",
            answer:
              "Sí, tenemos hotel disponible para todas las fechas con cupos limitados, recomendamos reservar con antelación."
          },
          {
            question: "¿Cuentan con servicios veterinarios?",
            answer:
              "Sí, tenemos servicios veterinarios disponibles con cita previa los martes y jueves. Puede agendar su cita en el horario que más le convenga. Solo debe contactarnos para coordinar."
          },
          {
            question: "¿Trabajan con felinos?",
            answer:
              "Por el momento, aún no trabajamos con felinos."
          }
       
        ]}
      />

      <Footer />
   
    </AnimationRevealPage>
  );
}
