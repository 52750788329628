import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container } from "components/misc/Layouts.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
// import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { supabase } from '../../backend/supabase.js';


const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const SectionHeading = tw.h2`text-4xl mt-0 xl:mt-0 sm:text-5xl font-black tracking-wide text-center`
const SectionHeading2 = tw.h2`text-xl sm:text-xl hidden xl:block font-black tracking-wide text-center bg-[#07a4b5] text-white px-6 py-3 cursor-pointer`
const Subheading = tw.h5`font-bold text-[#bff0f7]`
const Header = tw(SectionHeading)``;
const Header2 = tw(SectionHeading2)``;
const TabsControl = tw.div`flex  w-full lg:mx-6 md:w-[100%] overflow-x-auto whitespace-nowrap bg-[#0d6980] px-2 py-2 rounded leading-none mt-12 xl:mt-0`;
const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-white font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-[#07a4b5]! text-gray-100!`}
  }
`;
const TabCart = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;
const TabCart2 = styled.div`
  ${tw`fixed bottom-0 md:bottom-[inherit]  lg:top-[76px] flex items-center gap-3  z-[2] cursor-pointer px-6 py-3 md:mt-2 lg:mt-0 lg:mr-2 last:mr-0 text-black font-medium rounded-sm transition duration-300 text-sm sm:text-sm bg-[#07a4b5] text-white w-full xl:right-[50px] justify-center xl:w-[160px] text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700 cursor-pointer`}
  }
  ${props => props.active && tw`bg-[#07a4b5] text-gray-100!`}
  }
`;
const TabCart3 = styled.div`
  ${tw`cursor-pointer px-6 py-3 md:mt-2 lg:mt-0 lg:mr-2 last:mr-0 text-white  font-medium rounded-sm transition duration-300 text-sm sm:text-sm  w-full xl:w-1/3 text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-red-500! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;
const CardImageContainer2 = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`w-[12rem] h-[8rem] text-white bg-center bg-cover relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

// const CardHoverOverlay = styled(motion.div)`
//   background-color: rgba(255, 255, 255, 0.5); 
//   ${tw`absolute inset-0 flex justify-center items-center`}
// `;
const PrimaryButton2 = tw.button`px-8 py-3 font-bold rounded bg-[#07a4b5] text-gray-100 hocus:bg-[#07a4b5] hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`;

const CardButton = tw(PrimaryButton2)`text-sm mt-2 m-auto`;

// const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-[#07a4b5]`;
const CardTitle3 = tw.h5`text-sm font-semibold group-hover:text-[#07a4b5]`;
const CardTitle2 = tw.h4`text-2xl my-6 text-center font-semibold group-hover:text-[#07a4b5]`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardContent2 = tw.p`mt-3 text-sm font-medium text-white`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

const ContentCart2 = tw.div`fixed w-[350px] right-0 top-0 bg-[#07a4b5] h-full z-20 overflow-auto`;
const ContentCart3 = tw.div`fixed w-[250px] right-0 bottom-0 bg-green-400 absolute left-0 z-10 h-auto text-white border border-green-500 font-bold px-6 py-2 overflow-auto`;
const ContentCart4 = tw.div`flex justify-center items-center mb-6 gap-3 px-3 overflow-auto`;
const ContentCart5 = tw.div`flex flex-col justify-between items-center mb-3 mt-10 gap-3 px-3`;
const Cartbutton = tw.div`bg-white cursor-pointer text-white rounded-full font-bold`;
const Cartbutton2 = tw.div`bg-green-500 cursor-pointer text-white p-3 font-bold`;
const Cartbutton3 = tw.p`font-bold`;
const ContentWithPaddingXl= tw.div`max-w-screen-2xl mx-auto py-20 lg:py-24`;
export default ({
  heading = "Checkout the Menu",
  tabs = [
    {title: 'comida'}, {title: 'juguetes'}
  ]
 
}) => {
  
  let tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);
  const [products, setProducts] = useState([]);
  const [cards, setCards] = useState([]);
  const [link, setLink] = useState('');
  const [Total, setTotal] = useState(0);
  const [OpenCart, setOpenCart] = useState(false)
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const tabCartRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [thetabs, setThetabs] = useState([]);
  const [ErrorProducts, setErrorProducts] = useState(false)
  const [indiceProductoAgregado, setIndiceProductoAgregado] = useState(null);

  const setCookie = (name, value, days) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
  };

  const setCookieTotal = (name, value, days) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
  };
  const getCookie = name => {
    const cookieName = `${name}=`;
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return null;
  };
  const handleBuyNowClick = (card, index) => {

    setMostrarAlerta(true);
    setIndiceProductoAgregado(index);
    // Crear una nueva copia del arreglo de productos y agregar el nuevo producto
    const newProducts = [...products, card];

    // Actualizar el estado con el nuevo arreglo de productos
    setProducts(newProducts);
    toggleAlerta()
    // Calcular el total de los precios de los productos
    const total = newProducts.reduce((acc, product) => acc + product.price, 0);
    setTotal(total)
    // Crear el mensaje para WhatsApp con la lista de productos y el total
    const productsMessage = newProducts.map(product => `${product.title} - ${product.price}`).join('\n');
    const message = `Estoy interesado/a en los siguientes productos:
    -----------------------
    ${productsMessage}
    -----------------------
    Total = $${total.toFixed(2)}`; 

    // Construir el enlace de WhatsApp con el mensaje
    const whatsappLink = `https://wa.me/+18098996315?text=${encodeURIComponent(message)}`;
    // Actualizar el estado con el enlace de WhatsApp
    setLink(whatsappLink);
  };
  const toggleAlerta = () => {
    setMostrarAlerta(!mostrarAlerta);
    setTimeout(() => {
      setMostrarAlerta(false);
    }, 1000); // La alerta desaparecerá después de 3 segundos
  };

  const handleEliminarProducto = (id, price) => {
    // Filtra el producto que se va a eliminar
    const filteredProducts = products.filter(producto => producto.id !== id);

    // Convierte el total y el precio a números
    const TotalNumber = parseFloat(Total);
    const priceNumber = parseFloat(price);

    // Verifica si las conversiones fueron exitosas antes de realizar la resta
    if (!isNaN(TotalNumber) && !isNaN(priceNumber)) {
      // Calcula el nuevo total restando el precio del producto eliminado
      const newTotal = filteredProducts.reduce((total, product) => total + product.price, 0);

      // Actualiza el estado de productos y el total
      setProducts(filteredProducts);
      // Actualiza el total usando un callback para asegurarse de que se utilice el estado más actualizado
      setTotal( newTotal); // Convierte el nuevo total a string antes de actualizar el estado
    } else {
      console.error("Error: El total o el precio no son números válidos.");
    }
  };

  const getdata = async (category) => {
    try {
      const { data } = await supabase
        .from('products')
        .select()
        .eq('category', category);
  
      if (data && data.length > 0) {
        const filteredData = data.filter(item => item.status === true);
        setCards(filteredData);
        setErrorProducts(false)

        console.log(cards);
      } else {
        setCards([]); // Actualizamos cards a un array vacío
        console.log('No se encontraron productos');
        setErrorProducts(true)
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
      // Opcional: puedes lanzar una alerta o realizar alguna otra acción aquí
    }
  }
  

  const getCategoryNames = async () => {
    try {
      const { data, error } = await supabase
        .from('category')
        .select('name')
        .eq('status', true);
  
      if (error) {
        console.error('Error fetching categories:', error.message);
        return;
      }
  
      if (data) {
        const categoryNames = data.map(category => category.name);
        setThetabs(categoryNames);
      }
    } catch (error) {
      console.error('Error fetching categories:', error.message);
    }
  }

  
  useEffect(() => {
   
    getCategoryNames();
    // Función para cargar el carrito desde la cookie al cargar la página
    const loadCartFromCookie = () => {
      const cartFromCookie = JSON.parse(getCookie('cart'));
      const cartTotal = JSON.parse(getCookie('total'));
      if (cartFromCookie && cartTotal) {
        setProducts(cartFromCookie);
        setTotal(cartTotal);
      }
    };
    
    const fetchData = async () => {
      const { data } = await supabase
      .from('products')
      .select()
      .eq('category', 'Salud')
      
      if (data) {
        const filteredData = data.filter(data => data.category === 'Salud' && data.status === true);
      
        if (filteredData) {
          setTimeout(() => {
            setCards(filteredData);
            setLoading(false); // Marcamos que los datos han terminado de cargar
      
            if (tabCartRef.current) {
              tabCartRef.current.click();
            } else {
              console.error('El ref de la pestaña es nulo');
              // Aquí puedes lanzar una alerta u otro mensaje de error si lo deseas
            }
          }, 1000); // Retrasar la ejecución durante 1 segundo (1000 milisegundos)
        }
      }
      
    }
    fetchData()
    loadCartFromCookie();
  }, []); // Se ejecuta solo al montar el componente


  useEffect(() => {
    // Función para guardar el carrito en la cookie cada vez que cambie
    const saveCartToCookie = () => {
      setCookie('cart', JSON.stringify(products), 15); // Se guarda por 30 días
      setCookieTotal('total', JSON.stringify(Total), 15); // Se guarda por 30 días
    };

    saveCartToCookie();
  }, [products, Total]); // S

 
  const handleGetDataButtonClick = async (category) => {
    await getdata(category); // Llamada explícita al hacer clic en el botón
  };

  return (
    <>
      {OpenCart ? <ContentCart2>
        <div className="flex items-center">

          <div>
            <TabCart3 active={OpenCart ? true : null} onClick={e => setOpenCart(!OpenCart)}>X</TabCart3>

            <CardTitle2 className={`text-center`}> </CardTitle2>

          </div>
        </div>
        {products.map((item) => (
          <>
            <ContentCart4>

              <Cartbutton3>
                <CardImageContainer2 imageSrc={item.imageSrc}> </CardImageContainer2>
                <CardContent2>{item.title}:  ${item.price} </CardContent2>
              </Cartbutton3>
              <Cartbutton onClick={() => handleEliminarProducto(item.id, item.price)}><svg style={{width: "25px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ff0000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#ff0000" stroke-width="1.5"></circle> <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round"></path> </g></svg></Cartbutton>

            </ContentCart4>

          </>
        ))}
        <ContentCart5>
          <Cartbutton3>Total: $ {Total} pesos</Cartbutton3>
          <a href={link} > <Cartbutton2 > Enviar pedido</Cartbutton2></a>
          
        </ContentCart5>

      

      </ContentCart2> : null}

     

      <Container>

        <ContentWithPaddingXl>
          <HeaderRow>
            <Header>{heading}</Header>
            <TabCart2 active={OpenCart ? true : null} onClick={e => setOpenCart(!OpenCart)}>
               <svg style={{width: "25px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M7.2998 5H22L20 12H8.37675M21 16H9L7 3H4M4 8H2M5 11H2M6 14H2M10 20C10 20.5523 9.55228 21 9 21C8.44772 21 8 20.5523 8 20C8 19.4477 8.44772 19 9 19C9.55228 19 10 19.4477 10 20ZM21 20C21 20.5523 20.5523 21 20 21C19.4477 21 19 20.5523 19 20C19 19.4477 19.4477 19 20 19C20.5523 19 21 19.4477 21 20Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
              Ver carrito
            </TabCart2>
            <TabsControl  style={{ scrollbarColor: '#07a4b5 #07a4b5', scrollbarWidth: 'thin' }}>
            {thetabs.map((category, index) => (
              <TabControl
                key={index}
                active={activeTab === index}
                ref={tabCartRef}
                onClick={() => {
                  handleGetDataButtonClick(category);
                  setActiveTab(index);
                }}
              >
                {category}
              </TabControl>
            ))}
            </TabsControl>
           

         
          </HeaderRow>
          {thetabs.map((category, index) => (
            <TabContent
              key={index}
              variants={{
                current: {
                  opacity: 1,
                  scale: 1,
                  display: "flex",
                },
                hidden: {
                  opacity: 0,
                  scale: 0.8,
                  display: "none",
                }
              }}
              transition={{ duration: 0.4 }}
              initial={activeTab === index ? "current" : "hidden"}
              animate={activeTab === index ? "current" : "hidden"}
            >
              
              {loading == false ? cards.map((card, index) => (
                <CardContainer key={index}>
                  <Card className="group" initial="rest" whileHover="hover" whileTouch="touch" animate="rest">
                    <CardImageContainer imageSrc={card.imageSrc}>
                      <CardRatingContainer>
                        <CardRating>
                          {card.category}
                          
                        </CardRating>
                      </CardRatingContainer>

                    </CardImageContainer>
                    <CardText>
                      <CardTitle>{card.title}</CardTitle>
                      <CardContent>{card.content}</CardContent>
                      <CardPrice>RD ${card.price}.00</CardPrice>
                      {mostrarAlerta && index === indiceProductoAgregado ? (
                        <CardTitle3>
                          Producto agregado correctamente!
                        </CardTitle3>
                      ) : null}
                      <CardButton onClick={() => handleBuyNowClick(card, index)}>Agregar al carrito</CardButton>

                    </CardText>

                  </Card>
                </CardContainer>
              )) : <div><CardButton type="button" class="bg-indigo-500 ..." disabled>
                
              Cargando...

             
            </CardButton></div>}
            </TabContent>
          ))}
        </ContentWithPaddingXl>
        <DecoratorBlob1 />
        <DecoratorBlob2 />
      </Container>
    </>

  );
};

/* This function is only there for demo purposes. It populates placeholder cards */
// const getRandomCards = (category) => {

//   const cards = [
//     {
//       imageSrc:
//         "https://images.unsplash.com/photo-1512621776951-a57141f2eefd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
//       title: "Chicken Chilled",
//       content: "Chicken Main Course",
//       price: 5.99,
//       rating: "5.0",
//       reviews: "87",
//       category: 'comida',
//       status: true
//     },
//     {
//       imageSrc:
//         "https://images.unsplash.com/photo-1582254465498-6bc70419b607?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
//       title: "Samsa Beef",
//       content: "Fried Mexican Beef",
//       price: 3.99,
//       rating: "4.5",
//       reviews: "34",
//       category: 'comida',
//       status: false
//     },
//     {
//       imageSrc:
//         "https://images.unsplash.com/photo-1565310022184-f23a884f29da?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
//       title: "Carnet Nachos",
//       content: "Chilli Crispy Nachos",
//       price: 3.99,
//       rating: 3.9,
//       reviews: "26",
//       category: 'comida',
//       status: false
//     },
//     {
//       imageSrc:
//         "https://images.unsplash.com/photo-1534422298391-e4f8c172dddb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
//       title: "Guacamole Mex",
//       content: "Mexican Chilli",
//       price: 3.99,
//       rating: "4.2",
//       reviews: "95",
//       category: 'juguetes',
//       status: false
//     },
//     {
//       imageSrc:
//         "https://images.unsplash.com/photo-1550461716-dbf266b2a8a7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
//       title: "Chillie Cake",
//       content: "Deepfried Chicken",
//       price: 2.99,
//       rating: "5.0",
//       reviews: "61",
//       category: 'juguetes',
//       status: false

//     },
//     {
//       imageSrc:
//         "https://images.unsplash.com/photo-1476224203421-9ac39bcb3327??ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
//       title: "Nelli",
//       content: "Hamburger & Fries",
//       price: 7.99,
//       rating: "4.9",
//       reviews: "89",
//       category: 'juguetes',
//       status: false

//     },
  
//   ];
//   // Shuffle array
//   // return cards.filter(card => card.category === category && card.status === true);

  
  
//   if(getdata(category)){
//     return getdata(category);
//   }
//   return cards
// };
