import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";


import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

const PrimaryButtonBase = tw.button`px-8 py-3 font-bold  text-black bg-white hocus:bg-[#02e0ea] hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`;

const Container2 = tw.div`bg-[#0d6980] text-white px-6`;
const TwoColumn = tw.div` max-w-full   max-w-screen-xl mx-auto pt-5 pb-16 md:py-20 md:py-24 `;
const Column = tw.div`w-full `;
const TextColumn = styled(Column)(props => [
  tw`md:w-full mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const TextContent = tw.div`lg:py-8 text-center md:text-center`;

const Subheading = tw(SubheadingBase)`text-center md:text-center`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-center leading-tight`;
const Description = tw.p`mt-8 text-center md:text-center text-sm md:text-base lg:text-lg font-medium leading-relaxed text-[#02e0ea]`;

const Features = tw.div`mx-auto mx-0 grid md:grid-cols-2 max-w-2xl lg:max-w-none py-16`;
const Feature = tw.div`mt-10 lg:mt-8 flex items-center md:items-start flex-col md:mr-8 last:mr-0`;

const FeatureHeadingContainer = tw.div`flex items-center`;
const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-primary-500 text-center rounded p-1 text-2xl flex-shrink-0`}
  ${props => [
    props.iconRoundedFull && tw`rounded-full`,
    props.iconFilled && tw`border-0 bg-primary-500 text-gray-100`
  ]}
  svg {
    ${tw`w-16 h-16`}
  }
`;
const FeatureHeading = tw.div`ml-3 font-bold text-xl`;

const FeatureDescription = tw.div`mt-4 text-center md:text-left text-white leading-relaxed`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-12 text-sm inline-block mx-auto rounded-none `,
  props.buttonRounded && tw`rounded-full`
]);

export default ({
  subheading = "Our Expertise",
  heading = (
    <>
      Designed & Developed by <span tw="text-primary-500">Professionals.</span>
    </>
  ),
  description = " ¡Encuentra todo lo que necesitas para tu perrito aquí y bríndale el amor y cuidado que se merece! ",
  primaryButtonText = "Tienda",
  primaryButtonUrl = "https://ilcaninopetspa.com/products",
  buttonRounded = true,
  textOnLeft = true,
  features = null,
  iconRoundedFull = true,
  iconFilled = true,
  iconContainerCss = null
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  /*
   * Change the features variable as you like, add or delete objects
   * `icon` must be a React SVG component. See how BriefcaseIcon is imported above. For a full list of available icons, see Feather Icons.
   */
  const defaultFeatures = [
    {
      Icon: BriefcaseIcon,
      title: "Professionalism",
      description: "We have the best professional marketing people across the globe just to work with you.",
      iconContainerCss: tw`bg-teal-300 text-teal-800`
    },
    {
      Icon: MoneyIcon,
      title: "Affordable",
      description: "We promise to offer you the best rate we can - at par with the industry standard.",
      iconContainerCss: tw`bg-red-300 text-red-800`
    }
  ];

  if (!features) features = defaultFeatures;

  return (
    <Container2 id='producto'>
        <TwoColumn id='producto'>
          <TextColumn textOnLeft={textOnLeft}>
            <TextContent>
              <Subheading>{subheading}</Subheading>
              <Heading>{heading}</Heading>
              <Features>
                {features.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureHeadingContainer>
                      <FeatureIconContainer
                        iconFilled={iconFilled}
                        iconRoundedFull={iconRoundedFull}
                        css={feature.iconContainerCss || iconContainerCss}
                      >
                        {<feature.Icon />}
                      </FeatureIconContainer>
                      <FeatureHeading>{feature.title}</FeatureHeading>
                    </FeatureHeadingContainer>
                    <FeatureDescription>{feature.description}</FeatureDescription>
                  </Feature>
                ))}
              </Features>
              <Description>{description}</Description>

              <PrimaryButton buttonRounded={buttonRounded} as="a" href={primaryButtonUrl}>
                {primaryButtonText}
              </PrimaryButton>
            </TextContent>
          </TextColumn>
        </TwoColumn>
    </Container2>
  );
};
