import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import emailjs from 'emailjs-com';

import EmailIllustrationSrc from "images/contact-image.png";

const Container = tw.div`relative bg-[#0d6980] px-6`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const SectionHeading = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center`
const Subheading = tw.h5`font-bold text-[#07a4b5]`

const Heading = tw(SectionHeading)`mt-4 font-black text-left text-white text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-white/75`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500 px-3`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`
const PrimaryButtonBase = tw.button`px-8 py-3 font-bold rounded bg-[#07a4b5] text-gray-100 hocus:bg-[#02e0ea] hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`;
const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
  subheading = "¡Contáctanos!",
  heading = <> Estamos aquí para  <span tw="text-[#02e0ea]"> responder tus dudas</span> con la mayor brevedad posible.</>,
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "post",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_7k0y6eg', 'template_f8gdqyc', e.target, 'ZY153uvRzbCf49bSV')
      .then((result) => {
          console.log(result.text);
          alert("Message sent successfully!");
      }, (error) => {
          console.log(error.text);
          alert("Failed to send message, please try again later.");
      });
  };

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
           
            <Form onSubmit={sendEmail}>
              <Input type="email" name="email" placeholder="Your Email Address" required />
              <Input type="text" name="name" placeholder="Full Name" required />
              <Input type="text" name="subject" placeholder="Subject" required />
              <Textarea name="message" placeholder="Your Message Here" required />
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
