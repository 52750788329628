import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "images/nosotros-1.jpeg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg"
const SectionHeading2 = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center`
const Subheading2 = tw.h5`font-bold text-[#bff0f7] `
const Container = tw.div`relative`;
const Container2 = tw.div`bg-[#0d6980] px-6`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = styled(SvgDotPattern)(props => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
])

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw.h5`text-center md:text-left text-white`;
const Heading2 = tw.h2`mt-4 font-black text-white text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center text-white md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const PrimaryButtonBase = tw.button`px-8 py-3 font-bold rounded bg-white text-black hocus:bg-[#02e0ea] hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);


export default ({
  subheading = "Our Expertise",
  heading = (
    <>
      Il Canino: Excelencia en cuidado canino desde <span tw="text-[#02e0ea]">2021</span>
    </>
  ),
  description = "Il Canino es una empresa de origen familiar que inició su recorrido en el año 2021 con el objetivo de proporcionar servicios de peluquería de alta calidad para nuestros queridos mejores amigos de cuatro patas, los perros. En 2022, ampliamos nuestra dedicación al incorporar servicios veterinarios y, posteriormente, servicios de adiestramiento, consolidándonos como una propuesta excepcional en el mercado. En la actualidad, mantenemos con firmeza nuestra excelencia y propuesta de valor para satisfacer las necesidades de nuestros clientes.",
  primaryButtonText = "Tienda",
  primaryButtonUrl = "https://ilcaninopetspa.com/products",
  // imageSrc = {TeamIllustrationSrc},
  buttonRounded = true,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  textOnLeft = true
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container2>
      <Container id="nosotros">
        <TwoColumn>
          <ImageColumn>
            <Image css={imageCss} src={TeamIllustrationSrc} imageShadow={imageShadow} imageRounded={imageRounded} />
            {/* <img className="w-[200px] h-auto" src={image1} /> */}
            {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
          </ImageColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <TextContent>
              <Subheading2>{subheading}</Subheading2>
              <Heading2>{heading}</Heading2>
              <Description>{description}</Description>
              <PrimaryButton buttonRounded={buttonRounded} as="a" href={primaryButtonUrl}>
                {primaryButtonText}
              </PrimaryButton>
            </TextContent>
          </TextColumn>
        </TwoColumn>
      </Container>
    </Container2>
  );
};
